/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Banner } from '@/models/banner'
import { Banner as HoopBanner } from '@dena/hoop-proto-client/dist/notifications_entity_pb'
import { mapDate } from '../parser'

export function mapBanner(data: HoopBanner.AsObject | undefined): Banner | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title,
    imageUrl: data.imageUrl!.size2x,
    orderIndex: data.orderIndex,
    publishStartAt: mapDate(data.publishStartAt!),
    publishEndAt: mapDate(data.publishEndAt!),
    url: data.url,
  }
}
